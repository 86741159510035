import { Controller } from "@hotwired/stimulus";

import { composeMixins } from "./utils/compose_mixins";

import { BaseMixin } from "./mixins/printess/base_mixin";
import { FormFieldMixin } from "./mixins/printess/form_field_mixin";
import { LayoutMixin } from "./mixins/printess/layout_mixin";
import { LogoMixin } from "./mixins/printess/logo_mixin";
import { CssMixin } from "./mixins/printess/css_mixin";

const ComposedController = composeMixins(
  Controller,
  BaseMixin,
  FormFieldMixin,
  LayoutMixin,
  LogoMixin,
  CssMixin
);

Stimulus.register(
  "printess",
  class extends ComposedController {
    static targets = [
      "container",
      "saveTokenInput"
    ];

    static values = {
      customerLogoUploads: Object,
      shopToken: String,
      templateName: String,
      basketId: String,
      afterSaveTargetSelector: String,
      // The endpoint on the menuez side which we use for lightweight fetch updates
      editorUpdateEndpoint: String
    }
  }
);
