export const LogoMixin = (Base) =>
  class extends Base {
    uploadLogo() {
      if (!this.customerLogoUploadsValue) return;

      this.editor.api.getAllProperties().then((allProperties) => {
        this.traversePropertiesForLogoUpload(allProperties);
      });
    }

    traversePropertiesForLogoUpload(properties) {
      if (Array.isArray(properties)) {
        properties.forEach((item) => this.traversePropertiesForLogoUpload(item));
      } else if (typeof properties === "object" && properties !== null) {
        this.processPropertyForLogoUpload(properties);
      }
    }

    processPropertyForLogoUpload(property) {
      // it looks like this is not the right property (https://lico.inboxsharing.com/tickets/134048-VUD). Awaiting feedback on this
      // if (!property.imageMeta?.canUpload) return;

      const imageGroups = property.imageMeta?.imageGroups || [];
      for (const groupName of imageGroups) {
        const lowerCasedGroupName = groupName.toLowerCase();
        const uploadValue = this.customerLogoUploadsValue[lowerCasedGroupName];

        if (uploadValue) {
          this.editor.api.importImageFromUrl(uploadValue, true, property.id);
        }
      }
    }
  };
