export const CssMixin = (Base) =>
  class extends Base {
    pushCss() {
      const style = document.createElement('style')
      style.textContent = `
      bc-button[action="close"] { 
      display: none;
      }
      .bc-focus-catcher {
        opacity: 0;
      }
      `
      this.editor.ui.shadowRoot.append(style);
    }
  };
