import { flashError } from "@/utils/flash.js";

const AUTOSAVE_INTERVAL = 5000 // ms;

export const BaseMixin = (Base) =>
  class extends Base {
    connect() {
      this.enableSaveOnSubmit();
      this.loadPrintessEditor();
    }

    async loadPrintessEditor() {
      const printessLoader = await import( "https://editor.printess.com/printess-editor/loader.js" );

      this.editor = await printessLoader.load({
        token: this.shopTokenValue,
        templateName: this.templateName(),
        container: this.containerTarget,
        theme: "app.menuez.nl",
        basketId: this.basketIdValue,
        addToBasketCallback: this.addToBasketCallback.bind(this),
      });

      this.pushTextVariables();
      this.uploadLogo();
      this.pushCss();
      this.attachAutoSaver()
    }

    // Template will be the template name given if the template was never saved.
    // After customisation, though, it's the save token.
    templateName() {
      if (this.hasSaveToken()) {
        return this.saveTokenInputTarget.value
      }

      return this.templateNameValue
    }

    // Make sure we regularly save @ printess and mirror the token @ menuez
    // So that a user will not lose their work in the event of something going wrong.
    attachAutoSaver() {
      setInterval(async () => {
        if (this.editor.api.hasUnsavedChanges()) {
          console.debug("Autosaving.")

          const token = await this.editor.api.save();
          await this.pushNewSaveTokenToMenuez(token)
        } else {
          console.debug("Autosave skipped because no changes were found.")
        }
      }, AUTOSAVE_INTERVAL)
    }

    async updateSaveToken(token) {
      console.debug(`Updated save token: ${token}`);

      this.updateSaveTokenInputTarget(token),
      await this.pushNewSaveTokenToMenuez(token)
    }

    async addToBasketCallback(saveToken, _thumbnailUrl) {
      await this.updateSaveToken(saveToken)
      this.jumpToAfterSaveTarget();
    }

    updateSaveTokenInputTarget(saveToken) {
      this.saveTokenInputTarget.value = saveToken;
      this.saveTokenInputTarget.disabled = false;
    }

    async pushNewSaveTokenToMenuez(token) {
      try {
        await fetch(this.editorUpdateEndpointValue, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ product_personalization: { printess_save_token: token } }),
        });
      } catch (error) {
        console.error('Error:', error);
      }
    }

    jumpToAfterSaveTarget() {
      const target = document.querySelector(this.afterSaveTargetSelectorValue);

      if (!target) return;

      target.scrollIntoView({ behavior: "smooth" });
    }

    enableSaveOnSubmit() {
      this.element.addEventListener("submit", (event) => {
        this.saveAndSubmit(event);
      });
    }

    async saveAndSubmit(event) {
      event.preventDefault()

      this.ensureCommitInputIsPresent(event);

      // if we have a save token and there's nothing new to save, just submit the form and continue
      // with the usual menuez flow.
      if (this.hasSaveToken() && !this.editor.api.hasUnsavedChanges()) {
        return this.element.submit();
      }

      // otherwise, validate and try to save
      await this.validateAndSave(event)
    }

    async validateAndSave(saveEvent) {
      const errors = this.editor.api.validate()
      console.debug(errors)

      if (errors.length === 0) {
        const token = await this.editor.api.save()

        if (token.length === 0) {
          flashError("Something went wrong when attempting to save the design. Please try again.", { scrollTo: true })
          return saveEvent.stopPropagation()
        } else {
          await this.updateSaveToken(token)
        }
      } else {
        // there are errors
        flashError("Validatie mislukt. Voltooi alle stappen.", { scrollTo: true })

        this.resetEditor()

        return saveEvent.stopPropagation()
      }
    }

    // ProductPersonalizations#update has different routes to follow based on params[:commit]
    ensureCommitInputIsPresent(event) {
      if (
        event.submitter &&
        event.submitter.name &&
        event.submitter.name == "commit" &&
        event.submitter.value
      ) {
        let commit_input = this.element.querySelector(
          "input[type=hidden][name=commit]",
        );
        if (commit_input) {
          return;
        }

        commit_input = document.createElement("input");
        commit_input.name = "commit";
        commit_input.type = "hidden";
        commit_input.value = event.submitter.value;

        this.element.appendChild(commit_input);
      }
    }

    hasSaveToken() {
      return this.saveTokenInputTarget.value.length > 0;
    }

    resetEditor() {
      this.editor.api.clearSelection();
      this.editor.api.gotoFirstStep();
    }
  };
