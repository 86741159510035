export const FormFieldMixin = (Base) =>
  class extends Base {
    pushTextVariables() {
      this.element
        .querySelectorAll('*[data-action*="pushTextVariable"]')
        .forEach((textVariableInput) => {
          this.setFormFieldValue(textVariableInput.dataset.printessTargetParam, textVariableInput.value);
        });
    }

    pushTextVariable(event) {
      this.setFormFieldValue(event.params.target, event.target.value);
    }

    setFormFieldValue(field, value) {
      this.editor.api
        .setFormFieldValue(field, value)
        .then(() => {
          // We need to call clearSelection after updating form fields of type select
          this.editor.api.clearSelection();
        })
    }
  };
