function flashContainerClass(kind) {
  return `flash-container__${kind}`
}

function buildFlashFromTemplate(kind) {
  const template = document.getElementById("flash-template")
  const container = template.content.cloneNode(true).firstElementChild;

  container.classList.add(`flash-container__${kind}`)
  container.querySelector(".flash-class-target").classList.add(`alert-${kind}`)

  template.after(container)

  return container
}

function updateFlash(element, content) {
  element.querySelector(".flash-class-target").innerHTML = content
}

function flash(kind, message, { scrollTo = false } = {}) {
  const [existingFlash] = document.getElementsByClassName(flashContainerClass(kind))

  const flashElement = existingFlash || buildFlashFromTemplate(kind)
  updateFlash(flashElement, message)

  if (scrollTo) {
    flashElement.scrollIntoView({ behavior: "smooth" })
  }
}

const flashDanger = (message, options = {}) => flash("danger", message, options)
const flashError = flashDanger
const flashInfo = (message, options = {}) => flash("info", message, options)
const flashSuccess = (message, options = {}) => flash("success", message, options)

export { flash, flashDanger, flashError, flashInfo, flashSuccess }
